<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="time"
      :nudge-right="40"
      :disabled="timeOptions.timeDisabled"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          :label="lableName"
          outlined
          dense
          prepend-inner-icon="mdi-clock-time-four-outline"
          :disabled="timeOptions.timeDisabled"
          v-bind="attrs"
          v-on="on"
          :rules="getRules"
          :clearable="timeOptions.timeClearable"
          :single-line="timeOptions.SingleLine"
          @click:clear="$emit('clearTime')"
          hide-details="auto"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menu && !timeOptions.showSeconds"
        v-model="time"
        full-width
        locale="nl-be"
        format="24hr"
        scrollable
        @click:minute="$refs.menu.save(time)"
      ></v-time-picker>
      <v-time-picker
        v-if="menu && timeOptions.showSeconds"
        v-model="time"
        full-width
        locale="nl-be"
        format="24hr"
        scrollable
        :use-seconds="timeOptions.showSeconds"
        @click:second="$refs.menu.save(time)"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import validation from "../../validation";
export default {
  props: ["lableName", "timeOptions", "changeTime", "timeValue"],
  data() {
    return {
      menu: false,
      time: this.timeValue,
    };
  },
  created() {
    if (this.timeOptions.timeAutoSelectCurrent && !this.time) {
      let time = new Date(),
        houre = time.getHours() < 10 ? "0" + time.getHours() : time.getHours(),
        min =
          time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
      this.time = houre + ":" + min;
    }
  },
  watch: {
    time: function (time) {
      this.$emit("changedTime", time);
    },
  },
  computed: {
    getRules() {
      if (!this.timeOptions.timeFieldValidation) {
        return [];
      }
      return validation.getValidation(this.timeOptions.timeRules);
    },
  },
};
</script>

<style>
</style>